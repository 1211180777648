import { FC, useEffect, useRef } from 'react';
import styles from './DropdownMenu.module.scss';
import { MenuPosition } from '../../../model/enum/enums';

interface DropdownMenuProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
    trigger: HTMLElement | null;
    position?: MenuPosition;
}

const DropdownMenu: FC<DropdownMenuProps> = ({
    isOpen,
    onClose,
    children,
    trigger,
    position = 'bottom-left'
}) => {
    const modalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node) &&
                trigger && !trigger.contains(event.target as Node)) {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, onClose, trigger]);

    if (!isOpen) return null;

    const positionDropdownMenu = () => {
        if (!trigger) return {};

        const rect = trigger.getBoundingClientRect();
        const positions = {
            [MenuPosition.BottomRight]: {
                top: `${rect.bottom}px`,
                left: `${rect.left}px`,
            },
            [MenuPosition.BottomLeft]: {
                top: `${rect.bottom}px`,
                right: `${window.innerWidth - rect.right}px`,
            },
            [MenuPosition.TopRight]: {
                bottom: `${window.innerHeight - rect.top}px`,
                left: `${rect.left}px`,
            },
            [MenuPosition.TopLeft]: {
                bottom: `${window.innerHeight - rect.top}px`,
                right: `${window.innerWidth - rect.right}px`,
            },
        };

        return positions[position as MenuPosition];
    };

    return (
        <>
            <div className={styles.backdrop} onClick={onClose} />
            <div
                ref={modalRef}
                className={styles.modal}
                style={positionDropdownMenu()}
            >
                {children}
            </div>
        </>
    );
};

export default DropdownMenu;