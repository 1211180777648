export function ConvertURL(path: string) {
    switch (path) {
        case "/lessons":
            return 'Lessons';
        case "/discover":
            return 'Pick a word list';
        case "/learn":
            return 'Learn';
        case "/practice":
            return 'Practice';
        case "/progress":
            return 'Progress';
        default:
            return 'Lessons';
    }
}
