export const HighlightWord = (
    text: string | null = '',
    wordToHighlight: string | null = '',
    highlightColor: string = '#FF6B6B'
): JSX.Element => {
    if (!text || !wordToHighlight) return <>{text}</>;

    text = text.trim()
    text = text.charAt(0).toUpperCase() + text.slice(1)


    const regex = new RegExp(`(${wordToHighlight})`, 'gi');
    const parts = text.split(regex);

    return (
        <>{parts.map((part, index) =>
            part.toLowerCase() === wordToHighlight.toLowerCase() ?
                <span key={index} style={{ color: highlightColor }}>{part}</span> :
                part
        )}</>
    );
};

export const CapitalizeFirstLetter = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
};
