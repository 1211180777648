import React from 'react';
import styles from './NavigationSection.module.scss';
import { IconLocation } from '../../../../utils/URLUtils';
import Divider from '../Divider/Divider';

interface NavigationSectionProps {
    icon: string;
    text: string;
    hasArrow?: boolean;
    onClick?: () => void;
    thick?: boolean;
}

const NavigationSection: React.FC<NavigationSectionProps> = ({ icon, text, onClick, hasArrow, thick }) => (
    <>
        <div className={styles.section} onClick={onClick}>
            <div className={styles.row}>
                <img className={styles.icon} src={IconLocation(icon)} alt={text} />
                <span className={styles.text}>{text}</span>
            </div>
            {hasArrow && <img className={styles.arrow} src={IconLocation('chevron-right')} alt="Arrow" />}
        </div>
        <Divider thick={thick} />
    </>
);

export default NavigationSection;