import MainLearn from './MainLearn/MainLearn';
const Learn = () => {
    return (
        <>
            <MainLearn />
        </>
    )
};

export default Learn;

