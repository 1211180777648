import React from 'react';
import styles from './ButtonGroupSection.module.scss';

const ButtonGroupSection: React.FC = () => (
    <div className={styles.section}>
        <button className={styles.buttonGroups}>About us</button>
        <button className={styles.buttonGroups}>Privacy</button>
        <button className={styles.buttonGroups}>Share</button>
    </div>
);

export default ButtonGroupSection;