import { Ctx } from '../../../model/Lesson';
import { IconLocation } from '../../../utils/URLUtils';
import styles from './LessonsItem.module.scss';




export const LessonsItem = ({ ctx, imageSrc }: { ctx: Ctx, imageSrc: string }) => {
    return (
        <section className={styles.lessonsItem}>
            <div className={styles.lessonsItemContent}>
                <img className={styles.lessonsItemImage} src={imageSrc} alt={ctx.name} />
                <div className={styles.lessonsItemContentText}>
                    <h2>{ctx.name}</h2>
                    <p>0 of 10 completed</p>
                </div>
            </div>
            <div className={`${styles.lessonsItemContentButton} ${ctx.name === 'Greetings' ? styles.firstLesson : ''}`}>
                <img src={IconLocation('chevron-right')} alt="Button" />
            </div>
        </section>
    );
}