export class StorageUtils<T> {
    private storageKey: string;

    constructor(storageKey: string) {
        this.storageKey = storageKey;
    }

    get(): T | null {
        try {
            const data = localStorage.getItem(this.storageKey);
            if (!data) return null;
            return JSON.parse(data);
        } catch {
            return null;
        }
    }

    set(data: T | null): void {
        if (data) {
            localStorage.setItem(this.storageKey, JSON.stringify(data));
        } else {
            localStorage.removeItem(this.storageKey);
        }
    }

    update(partialData: Partial<T>): T | null {
        const currentData = this.get();
        if (!currentData) return null;

        const updatedData = { ...currentData, ...partialData };
        this.set(updatedData);
        return updatedData;
    }
}