import { MODAL_COMPONENTS } from '../../../hook/componentRegistry';
import { useComponentModal } from '../../../hook/useComponentModal';
import styles from './ComponentModal.module.scss';

const ComponentModal = () => {
    const { isOpen, componentKey, componentProps, animation } = useComponentModal();

    if (!isOpen || !componentKey) return null;

    const ModalComponent = MODAL_COMPONENTS[componentKey];

    if (!ModalComponent) {
        console.error(`No component found for key: ${componentKey}`);
        return null;
    }

    return (
        <div
            key={`${componentKey}-${animation}`}
            className={`${styles.componentModal} ${styles[animation || 'slideRight']}`}
        >
            <div className={styles.modalContainer}>
                <ModalComponent {...componentProps} />
            </div>
        </div>
    );
};

export default ComponentModal;