import styles from './ProgressHeader.module.scss';
import { ImageLocation } from '../../../utils/URLUtils';
import ProgressBar from '../../ui/ProgressBar/ProgressBar';
import { useLearning } from '../../../hook/useLearning';


const ProgressHeader = () => {
    const { learningInfo } = useLearning();
    const summary = learningInfo?.MyAccount?.Summary;
    return (
        <section className={styles.progressHeader}>
            <img className={styles.backGroundImage} src={ImageLocation('discover-gesture.png')} alt="logo" />
            <div className={styles.content}>
                <div className={styles.avatarSection}>
                    <img className={styles.avatar} src={summary?.ImageUrl ?? ImageLocation('Profile-4.png')} alt="Avatar" />
                    <div className={styles.textContent}>
                        <h2>Knowledge Map</h2>
                        <h3>uncovered words</h3>
                    </div>
                    <h1 className={styles.uncoverCount}>{summary?.UncoveredCount}</h1>
                </div>
                <ProgressBar knownCount={100} learningCount={0} unknownCount={0} totalCount={1000} height={7} />

                <div className={styles.dataSection}>
                    <div className={styles.dataItem}>
                        <h3 className={styles.knewTitle}>Knew</h3>
                        <h4 className={styles.knewTitle}>{summary?.KnewCount}</h4>
                    </div>
                    <div className={styles.dataItem}>
                        <h3 className={styles.learningTitle}>Learning</h3>
                        <h4 className={styles.learningTitle}>{summary?.LearningCount}</h4>
                    </div>
                    <div className={styles.dataItem}>
                        <h3 className={styles.toLearnTitle}>To Learn</h3>
                        <h4 className={styles.toLearnTitle}>{summary?.ToLearnCount}</h4>
                    </div>
                </div>
                <button className={styles.button}>Continue</button>
            </div>
        </section>
    );
};

export default ProgressHeader;