import React, { useState } from 'react';
import styles from './MainNavigation.module.scss';
import { ImageLocation, RazorSite } from '../../../utils/URLUtils';
import useUser from '../../../hook/useUser';
import NavigationSection from './NavigationSection/NavigationSection';
import ThemeSection from './ThemeSection/ThemeSection';
import PronounceSection from './PronounceSection/PronounceSection';
import ButtonGroupSection from './ButtonGroupSection/ButtonGroupSection';
import VideoSection from './VideoSection/VideoSection';
import { useIFrameModal } from '../../../hook/useIFrameModal';


const MainNavigation: React.FC<{ toggleNavigation: () => void, show: boolean }> = ({ toggleNavigation, show }) => {
    const { open } = useIFrameModal();
    const [theme, setTheme] = useState(false);
    const [pronounce, setPronounce] = useState(false);
    const { userInfo } = useUser();

    const handleOpenModal = (url: string, title: string) => {
        open(url, title);
        toggleNavigation();
    };

    return (
        <>
            {show && (
                <>
                    <div className={styles.overlay} onClick={toggleNavigation} />
                    <nav className={styles.mainNavigation}>
                        <img className={styles.logo} src={ImageLocation('logo.webp')} alt="Logo" />
                        <button className={styles.proButton}>Get WordUp Pro</button>

                        <NavigationSection icon="search" text="Search" thick />

                        <ThemeSection theme={theme} setTheme={setTheme} />

                        <PronounceSection pronounce={pronounce} setPronounce={setPronounce} thick />

                        <NavigationSection
                            icon="user-check"
                            text="Account Settings"
                            hasArrow
                            onClick={() => handleOpenModal(RazorSite('settings/account', userInfo?.Token), 'Account Settings')}
                        />

                        <NavigationSection
                            icon="settings"
                            text="More Settings"
                            hasArrow
                            onClick={() => handleOpenModal(RazorSite('settings/preferences', userInfo?.Token), 'Preferences')}
                            thick
                        />

                        <NavigationSection icon="quote" text="My Interests" hasArrow />

                        <NavigationSection icon="chrome-extension" text="Chrome Extension" />

                        <NavigationSection icon="repeat" text="Sync devices" />

                        <NavigationSection icon="tool" text="Support & feedback" hasArrow />

                        <ButtonGroupSection />
                        <VideoSection />

                        <p className={styles.version}>Version 6.5.10</p>
                    </nav >
                </>
            )}
        </>
    );
};

export default MainNavigation;