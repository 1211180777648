import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Spinner from '../ui/Spinner/Spinner';
import { BaseApi, GetFriendsUrl, GetNearbyPeopleUrl } from '../../utils/URLUtils';
import { HomePage } from './HomePage';
import ErrorMessage from '../ui/Error/Error';
import { LanguageLevel } from '../../model/enum/enums';
import { useLocation, useNavigate } from 'react-router-dom';
import useUser from '../../hook/useUser';
import { useLearning } from '../../hook/useLearning';
import { useSocial } from '../../hook/useSocial';

const Load: React.FC = () => {
    const hasFetchStarted = useRef(false);
    const { setUserInfo } = useUser();
    const { setLearningInfo } = useLearning();
    const { setSocialInfo } = useSocial();
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const location = useLocation();
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const token = queryParams.get('t');
    const navigate = useNavigate();

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true);
            const responseUser = await fetch(BaseApi('settings', token!));
            const responseLearning = await fetch(BaseApi('learning', token!));
            if (!responseUser.ok || !responseLearning.ok) {
                throw new Error('User or Learning Response not OK');
            }
            const dataUser = await responseUser.json();
            const dataLearning = await responseLearning.json();
            const responseFriends = await fetch(GetFriendsUrl(dataUser.UserId));
            const responsePeopleNearBy = await fetch(GetNearbyPeopleUrl(dataUser.UserId, dataUser.CountryCode ?? 'GB'));
            if (!responseFriends.ok || !responsePeopleNearBy.ok) {
                throw new Error('Friends or People Near By Response not OK');
            }
            const dataFriends = await responseFriends.json();
            const dataPeopleNearBy = await responsePeopleNearBy.json();
            setUserInfo({ ...dataUser, Token: token! });
            setLearningInfo(dataLearning);
            setSocialInfo({
                Friends: dataFriends,
                PeopleNearBy: dataPeopleNearBy
            });
            const home = dataLearning?.LanguageLevel === LanguageLevel.Basic ? 'lessons' : "discover";
            navigate(`/${home}`);
        } catch (error) {
            console.error(error);
            setIsError(true);
        } finally {
            setIsLoading(false);
        }
    }, [token, navigate, setUserInfo, setLearningInfo, setSocialInfo]);

    useEffect(() => {
        if (token && !hasFetchStarted.current) {
            hasFetchStarted.current = true;
            fetchData();
        }
    }, [fetchData, token]);


    if (isLoading) {
        return <Spinner />;
    }

    else if (isError) {
        return <ErrorMessage message="Error loading data. Please try again." />;
    }
    else {
        return <HomePage />;
    }
};

export default memo(Load);