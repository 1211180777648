import { User, Learning, Social } from "../model/User";
import { StorageUtils } from "./BaseStorageUtils";

export const STORAGE_KEYS = {
    USER: 'user_info',
    LEARNING: 'learning_info',
    SOCIAL: 'social_info'
} as const;

export const userStorage = new StorageUtils<User>(STORAGE_KEYS.USER);
export const learningStorage = new StorageUtils<Learning>(STORAGE_KEYS.LEARNING);
export const socialStorage = new StorageUtils<Social>(STORAGE_KEYS.SOCIAL);