import styles from './Friends.module.scss';
import useUser from '../../../hook/useUser';
import { useState } from 'react';
import { FriendsItem } from './FriendsItem/FriendsItem';
import { FriendFooter } from './FriendFooter/FriendFooter';
import { FriendSummaryData } from '../../../model/FriendSummaryData';
import { useLearning } from '../../../hook/useLearning';
import { useSocial } from '../../../hook/useSocial';

const Friends = () => {
    const { learningInfo } = useLearning();
    const { socialInfo } = useSocial();
    const peopleNearBy = socialInfo?.PeopleNearBy;
    const friends = socialInfo?.Friends;
    const [selectedTab, setSelectedTab] = useState('friends');
    const currentWeekProgress = learningInfo?.MyAccount?.MyProgress?.Weekly?.Items[3]?.Value;
    const you: FriendSummaryData = {
        Handle: '',
        Avatar: learningInfo?.MyAccount?.Summary?.ImageUrl,
        DisplayName: 'You',
        SubscriptionId: '',
        CurrentWeekProgress: currentWeekProgress || 0,
    }
    const friendsWithYou = friends
        ? [...friends, you].sort((a, b) => b.CurrentWeekProgress - a.CurrentWeekProgress)
        : [you];

    const peopleNearByWithYou = peopleNearBy
        ? [...peopleNearBy, you].sort((a, b) => b.CurrentWeekProgress - a.CurrentWeekProgress)
        : [you];


    return (
        <section className={styles.friends}>
            <h2>Last 7 days</h2>
            <div className={styles.friendsContainer}>
                <div className={styles.tabsContainer}>
                    <div className={styles.tab + ' ' + (selectedTab === 'friends' ? styles.active : '')}
                        onClick={() => setSelectedTab('friends')}>Friends League</div>
                    <div className={styles.tab + ' ' + (selectedTab === 'peopleNearBy' ? styles.active : '')}
                        onClick={() => setSelectedTab('peopleNearBy')}>People Nearby</div>
                </div>
                <hr className={styles.thick} />
                <div className={styles.friendsList}>
                    {selectedTab === 'friends' ? friendsWithYou?.map((friend, index) => (
                        <div key={friend.Handle}>
                            <FriendsItem friend={friend} index={index} />
                            <hr />
                        </div>
                    )) : peopleNearByWithYou?.map((friend, index) => (
                        <div key={friend.Handle}>
                            <FriendsItem friend={friend} index={index} />
                            <hr />
                        </div>
                    ))}
                    <hr />
                </div>
            </div>
            <FriendFooter selectedTab={selectedTab} />

        </section>
    );
};

export default Friends;