import { FC } from 'react';
import { ImageLocation } from '../../../../../utils/URLUtils';
import AudioPlayer from '../../AudioPlayer/AudioPlayer';
import styles from './PlaySection.module.scss';

interface PlaySectionProps {
    type?: string;
    typeClass?: string;
    audioText: string;
    showFlag: boolean;
    nativeLanguageCode?: string;
    onFlagClick: () => void;
}

const PlaySection: FC<PlaySectionProps> = ({
    type,
    typeClass,
    audioText,
    showFlag,
    nativeLanguageCode,
    onFlagClick
}) => {
    return (
        <div className={styles.playSection}>
            <span className={typeClass}>{type}</span>
            <div className={styles.icons}>
                <AudioPlayer text={audioText} />
                {showFlag && (
                    <img
                        src={ImageLocation(`LangFlags/${nativeLanguageCode}.webp`)}
                        alt='volume'
                        onClick={onFlagClick}
                    />
                )}
            </div>
        </div>
    );
};

export default PlaySection;