export const enum LanguageLevel {
    Basic = "Basic",
    Beginner = "Beginner",
    Intermediate = "Intermediate",
    Advanced = "Advanced",
    Fluent = "Fluent",
    Native = "Native"
}

export const enum Http {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    DELETE = "DELETE",
    PATCH = "PATCH",
}

export const enum Period {
    Daily = "Daily",
    Weekly = "Weekly",
    Monthly = "Monthly",
}

export const enum CardBackgroundOption {
    VideoOrImage = "VideoOrImage",
    Image = "Image",
    Nothing = "Nothing",
}

export const enum Voice {
    gb = "gb",
    us = "us",
}

export const enum ContentType {
    Image = "Image",
    Video = "Video",
}

export const enum ComponentModalAnimation {
    SlideRight = "slideRight",
    SlideUp = "slideUp",
    Rotate = "rotate",
}

export const enum MenuPosition {
    BottomLeft = "bottom-left",
    BottomRight = "bottom-right",
    TopLeft = "top-left",
    TopRight = "top-right",
}

export const PathName = {
    learn: "/learn",
    practice: "/practice",
    progress: "/progress",
    discover: "/discover",
    lessons: "/lessons",
}

export const enum ResponseType {
    Json = "json",
    Text = "text",
}

export const enum HttpMethod {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    DELETE = "DELETE",
    PATCH = "PATCH",
}
