import React from 'react';
import styles from './PronounceSection.module.scss';
import { IconLocation } from '../../../../utils/URLUtils';
import Divider from '../Divider/Divider';

interface PronounceSectionProps {
  pronounce: boolean;
  setPronounce: (value: boolean) => void;
  thick?: boolean;
}

const PronounceSection: React.FC<PronounceSectionProps> = ({ pronounce, setPronounce, thick }) => (
  <>
    <div className={styles.section}>
      <div className={styles.row}>
        <img className={styles.icon} src={IconLocation('volume-1')} alt="Pronounce" />
        <span className={styles.text}>Pronounce</span>
      </div>
      <div className={styles.switch}>
        <div
          className={pronounce ? styles.activeGray : styles.notActive}
          onClick={() => setPronounce(true)}>
          <span>Off</span>
        </div>
        <div
          className={!pronounce ? styles.active : styles.notActive}
          onClick={() => setPronounce(false)}>
          <span>On</span>
        </div>
      </div>
    </div>
    <Divider thick={thick} />
  </>
);

export default PronounceSection;