import { FC, useCallback, useEffect, useState } from 'react';
import useFetch from "../../../../../hook/useFetch";
import useUser from "../../../../../hook/useUser";
import { ResponseType } from "../../../../../model/enum/enums";
import { Word } from "../../../../../model/Word";
import { ProTipData } from "../../../../../model/WordData";
import { HighlightWord } from "../../../../../utils/TextUtils";
import { RazorSite } from "../../../../../utils/URLUtils";
import Spinner from "../../../../ui/Spinner/Spinner";
import PlaySection from "../../../FirstWizard/TextContent/PlaySection/PlaySection";
import styles from "./ProTip.module.scss";


interface ProTipProps {
    word: Word;
    tip: ProTipData;
}

const ProTip: FC<ProTipProps> = ({ word, tip }) => {

    const { userInfo } = useUser();
    const { isLoading, sendChain } = useFetch<string>();
    const [showFlag, setShowFlag] = useState(true);
    const [translateDefinition, setTranslateDefinition] = useState<string | null>(null);
    const [translateExample, setTranslateExample] = useState<string | null>(null);

    const handleFlagClick = useCallback(async () => {
        setShowFlag(false);
        const results =
            await sendChain([
                { url: RazorSite(`translation/phrase/${tip?.description}`, userInfo?.Token), responseType: ResponseType.Text },
                { url: RazorSite(`translation/phrase/${tip?.example}`, userInfo?.Token), responseType: ResponseType.Text }
            ]);
        if (results) {
            setTranslateDefinition(results[0].response);
            setTranslateExample(results[1].response);
        }
    }, [sendChain, userInfo?.Token, tip]);

    useEffect(() => {
        if (userInfo?.NativeLanguageCode === 'en') {
            setShowFlag(false);
        }
    }, [userInfo?.NativeLanguageCode]);


    if (isLoading) return <Spinner />

    return (
        <div className={styles.proTip}>
            <div className={styles.container}>
                <div className={styles.header}>
                    <PlaySection
                        type={tip?.title}
                        typeClass={styles.title}
                        audioText={`${tip?.description}. for example. ${tip?.example}`}
                        showFlag={showFlag}
                        nativeLanguageCode={userInfo?.NativeLanguageCode}
                        onFlagClick={handleFlagClick}
                    />
                </div>
                <span className={styles.description}>
                    {HighlightWord(tip?.description, word.Text, '#AE24F6')}
                    <div className={styles.translate}>
                        {translateDefinition}
                    </div>
                </span>

                <div className={styles.imageContainer}>
                    <img src={tip.imageUrl} alt='SlideImage' className={styles.coverImage} />
                    <div className={styles.example}>
                        {HighlightWord(tip?.example, word.Text, '#AE24F6')}
                        <div className={styles.translate}>
                            {translateExample}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default ProTip;
