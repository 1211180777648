import styles from './ThemeSection.module.scss';
import { IconLocation } from '../../../../utils/URLUtils';
import Divider from '../Divider/Divider';

interface ThemeSectionProps {
    theme: boolean;
    setTheme: (value: boolean) => void;
    thick?: boolean;
}

const ThemeSection: React.FC<ThemeSectionProps> = ({ theme, setTheme, thick }) => (
    <>
        <div className={styles.section}>
            <div className={styles.row}>
                <img className={styles.icon} src={IconLocation('tablet')} alt="Theme" />
                <span className={styles.text}>Theme</span>
            </div>
            <div className={styles.switch}>
                <div
                    className={theme ? styles.active : styles.notActive}
                    onClick={() => setTheme(true)}>
                    <img src={IconLocation('moon')} alt="Moon" />
                </div>
                <div
                    className={!theme ? styles.active : styles.notActive}
                    onClick={() => setTheme(false)}>
                    <img src={IconLocation('sun')} alt="Sun" />
                </div>
            </div>
        </div>
        <Divider thick={thick} />
    </>
);

export default ThemeSection;