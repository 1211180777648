import { IconLocation, ImageLocation } from '../../../utils/URLUtils';
import styles from './DiscoverItem.module.scss';
import { WordList } from '../../../model/WordList';
import WordCountHR from '../../ui/ProgressBar/ProgressBar';


export const DiscoverItem = ({ wordlist }: { wordlist: WordList }) => {
    return (
        <div className={styles.discoverItem}>
            <img
                src={!wordlist.IconName ? wordlist.ImageUrl :
                    wordlist.IconName?.includes('http')
                        ? wordlist.IconName :
                        IconLocation(wordlist.IconName)}
                alt={wordlist.Title} />
            <div className={styles.discoverItemContentWrapper}>
                {wordlist.IsMyWords && <div className={styles.discoverItemContentFirst}>Recommended</div>}
                <div className={styles.discoverItemContent}>
                    <div className={styles.discoverItemContentText}>
                        <h3>{wordlist.Title}</h3>
                        <WordCountHR
                            knownCount={wordlist.KnownCount}
                            learningCount={wordlist.LearningCount}
                            unknownCount={wordlist.UnknownCount}
                            totalCount={wordlist.TotalCount}
                        />
                        <p>{wordlist.UncoveredCountText}</p>
                    </div>
                    <div className={styles.discoverItemContentButton}>
                        <img src={IconLocation('chevron-right')} alt="Button" />
                    </div>
                    {wordlist.IsRestricted && <img className={styles.pro} src={ImageLocation('Pro.png')} alt="Pro" />}
                </div>
            </div>
        </div>
    );
}