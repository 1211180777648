import { useCallback, useEffect, useState } from 'react';
import useFetch from "../../../hook/useFetch";
import { useLearning } from '../../../hook/useLearning';
import useUser from '../../../hook/useUser';
import { Voice } from '../../../model/enum/enums';
import { Word } from '../../../model/Word';
import { GetWordApiUrl } from '../../../utils/URLUtils';
import Spinner from "../../ui/Spinner/Spinner";
import ImageSection from '../ImageSection/ImageSection';
import ActionButtons from './ActionButtons/ActionButtons';
import AudioPlayButton from './AudioPlayButton/AudioPlayButton';
import styles from './MainLearn.module.scss';

const MainLearn = () => {
    const { userInfo } = useUser();
    const { learningInfo } = useLearning();
    const { isLoading, send } = useFetch<any>();
    const [word, setWord] = useState<Word>({} as Word);
    const [index, setIndex] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);


    const fetchWord = useCallback(async () => {
        const wordId = learningInfo?.UnknownWordIds[index];
        if (!wordId) return;
        const { response, errorMessage } = await send(GetWordApiUrl(wordId));
        if (errorMessage) {
            console.log(errorMessage);
            return;
        }
        setWord(response);
    }, [send, index, learningInfo?.UnknownWordIds]);

    useEffect(() => {
        fetchWord();
    }, [fetchWord]);


    if (isLoading) return <Spinner />

    return (
        <article className={styles.mainLearn}>
            <div className={styles.mainContent}>
                <ImageSection word={word} />
                <AudioPlayButton
                    wordId={word.ID}
                    onPlayStart={() => setIsPlaying(true)}
                    onPlayEnd={() => setIsPlaying(false)}
                />
            </div>
            <div className={`${styles.wordInfo} ${isPlaying ? styles.playing : ''}`}>
                <h1>{word.Text}</h1>
                <p>/{userInfo?.Voice === Voice.gb ? word.BritishPhonetic : word.AmericanPhonetic}/</p>
            </div>
            <ActionButtons
                word={word}
                index={index}
                setIndex={setIndex}
            />
        </article>
    );
};

export default MainLearn;