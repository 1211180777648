import { FC, useCallback, useEffect, useState } from "react";
import useFetch from "../../../hook/useFetch";
import useUser from "../../../hook/useUser";
import { ResponseType } from "../../../model/enum/enums";
import { Word } from "../../../model/Word";
import { WordData } from "../../../model/WordData";
import { GetWellDoneLottieUrl, RazorSite } from "../../../utils/URLUtils";
import Spinner from "../../ui/Spinner/Spinner";
import HeaderSection from "../HeaderSection/HeaderSection";
import MainSection from "./MainSection/MainSection";

interface FinalWizardProps {
    word: Word;
    wordData: WordData;
}

const FinalWizard: FC<FinalWizardProps> = ({ word, wordData }) => {
    const { isLoading, sendChain, send } = useFetch<any>();
    const [translateWord, setTranslateWord] = useState('');
    const [animationData, setAnimationData] = useState(null);
    const { userInfo, isNonEnglishNativeLanguage } = useUser();

    const fetchData = useCallback(async () => {
        const randomNumber = Math.floor(Math.random() * 15) + 1;
        if (isNonEnglishNativeLanguage()) {
            const results = await sendChain([
                { url: GetWellDoneLottieUrl(randomNumber, userInfo?.Token), responseType: ResponseType.Json },
                { url: RazorSite(`translation/phrase/${word.Text}`, userInfo?.Token), responseType: ResponseType.Text }
            ]);

            setAnimationData(results[0].response);
            setTranslateWord(results[1].response);
        }
        else {
            const { response } =
                await send(GetWellDoneLottieUrl(randomNumber, userInfo?.Token));
            if (response)
                setAnimationData(response);
        }

    }, [sendChain, send, word.Text, userInfo?.Token, isNonEnglishNativeLanguage]);


    useEffect(() => {
        fetchData();
    }, [fetchData]);


    if (isLoading) return <Spinner />

    return (
        <>
            <HeaderSection word={word} showProgress={false} />
            <MainSection word={word} wordData={wordData} translateWord={translateWord} animationData={animationData} />
        </>
    );
};

export default FinalWizard;
