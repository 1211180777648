import { FC } from 'react';
import { useComponentModal } from '../../../../hook/useComponentModal';
import { ComponentModalAnimation } from '../../../../model/enum/enums';
import { Word } from '../../../../model/Word';
import { WordData } from '../../../../model/WordData';
import { HighlightWord } from '../../../../utils/TextUtils';
import styles from './ThirdWizardTextContent.module.scss';

interface ThirdWizardTextContentProps {
    word: Word;
    wordData: WordData;
}

const ThirdWizardTextContent: FC<ThirdWizardTextContentProps> = ({ word, wordData }) => {
    const primarySense = wordData?.Senses?.find(sense => sense.id === word.PrimarySenseId);
    const { open } = useComponentModal();

    const navigate = (route: string) => {
        open(route, { word, wordData }, ComponentModalAnimation.Rotate);
    }

    return (
        <div className={styles.textContentWrapper}>
            <div className={styles.textContent} >
                <div className={styles.textContentContainer}>
                    <h1 className={styles.clareTitle}>Often appears as</h1>
                    <div className={styles.clareItems}>
                        {primarySense?.cl.map((cl, index) =>
                            <div key={index} className={styles.clareItem}>
                                <div className={styles.rectangle}></div>
                                <div className={styles.clareDescription}>
                                    {HighlightWord(cl, word.Text, '#AE24F6')}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className={styles.buttonContainer}>
                <button className={styles.backButton} onClick={() => navigate('SecondWizard')}>Back</button>
                <button className={styles.continueButton} onClick={() => navigate('FinalWizard')}>Done</button>
            </div>
        </div>
    );
};

export default ThirdWizardTextContent;