import { FC } from 'react';
import { Word } from '../../../model/Word';
import { WordData } from '../../../model/WordData';
import HeaderSection from '../HeaderSection/HeaderSection';
import ImageSection from '../ImageSection/ImageSection';
import styles from './ThirdWizard.module.scss';
import ThirdWizardTextContent from './ThirdWizardTextContent/ThirdWizardTextContent';

interface ThirdWizardProps {
    word: Word;
    wordData: WordData;
}

const ThirdWizard: FC<ThirdWizardProps> = ({ word, wordData }) => {

    return (
        <>
            <HeaderSection word={word} fillCount={75} />
            <div className={styles.mainContent}>
                <ImageSection word={word} hasRank={false} />
                <ThirdWizardTextContent
                    word={word}
                    wordData={wordData}
                />
            </div>
        </>
    );
};

export default ThirdWizard;