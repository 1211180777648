import React from 'react';
import styles from './VideoSection.module.scss';
import { IconLocation } from '../../../../utils/URLUtils';

const VideoSection: React.FC = () => (
    <div className={styles.section}>
        <div className={styles.video}>
            <img src={IconLocation('play-circle')} alt="Video" />
            <span>Learn how to use WordUp</span>
        </div>
    </div>
);

export default VideoSection;