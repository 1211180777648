import { useLearning } from '../../hook/useLearning';
import styles from './Discover.module.scss';
import { DiscoverItem } from './DiscoverItem/DiscoverItem';
const Discover = () => {
    const { learningInfo } = useLearning();
    const wordlists = learningInfo?.WordLists;
    const completedWordLists = wordlists?.filter(wordlist => wordlist.IsCompleted);
    const unCompletedWordLists = wordlists?.filter(wordlist => !wordlist.IsCompleted);

    return (
        <article className={styles.discover}>
            {unCompletedWordLists?.map((wordlist, index) => (
                <DiscoverItem key={index} wordlist={wordlist} />
            ))}
            <h2>Fully uncovered</h2>
            {completedWordLists?.map((wordlist, index) => (
                <DiscoverItem key={index} wordlist={wordlist} />
            ))}
        </article>

    );

}

export default Discover;