import { useSelector } from 'react-redux';
import { appDispatch, RootState } from '../store/store';
import { learningActions } from '../store/features/learningSlice';
import { Learning } from '../model/User';
import { useCallback, useEffect, useMemo } from "react";
import { bindActionCreators } from "redux";

export const useLearning = () => {
    // Memoize bound actions
    const ac = useMemo(
        () => bindActionCreators(learningActions, appDispatch),
        []
    );

    const { learningInfo } = useSelector((state: RootState) => state.learning);

    // Load learning data on mount if not already loaded
    useEffect(() => {
        if (!learningInfo) {
            ac.getLearning();
        }
    }, [ac, learningInfo]);

    const setLearningInfo = useCallback((info: Learning | null) => {
        ac.setLearning(info);
    }, [ac]);

    const updateLearningInfo = useCallback((info: Partial<Learning>) => {
        ac.updateLearning(info);
    }, [ac]);

    return useMemo(() => ({
        learningInfo,
        setLearningInfo,
        updateLearningInfo,
    }), [
        learningInfo,
        setLearningInfo,
        updateLearningInfo,
    ]);
};