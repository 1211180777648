import { useCallback, useEffect, useMemo } from "react";
import { useSelector } from 'react-redux';
import { bindActionCreators } from "redux";
import { User } from '../model/User';
import { userActions } from '../store/features/userSlice';
import { appDispatch, RootState } from '../store/store';

const useUser = () => {
    // Memoize bound actions to prevent recreating on every render
    const ac = useMemo(
        () => bindActionCreators(userActions, appDispatch),
        []
    );

    const { userInfo, isUserLoaded } = useSelector((state: RootState) => state.user);

    useEffect(() => {
        if (!isUserLoaded) {
            ac.getUser();
        }
    }, [ac, isUserLoaded]);

    const setUserInfo = useCallback((info: User | null) => {
        ac.setUser(info);
    }, [ac]);

    const updateUserInfo = useCallback((info: Partial<User>) => {
        ac.updateUser(info);
    }, [ac]);

    const handleLogout = useCallback(() => {
        ac.logout();
    }, [ac]);

    const isAuthenticated = useCallback(() => {
        return !!userInfo?.Token;
    }, [userInfo?.Token]);


    const isNonEnglishNativeLanguage = useCallback(() => {
        return userInfo?.NativeLanguageCode !== 'en';
    }, [userInfo?.NativeLanguageCode]);

    return useMemo(() => ({
        userInfo,
        setUserInfo,
        updateUserInfo,
        handleLogout,
        isAuthenticated,
        isUserLoaded,
        isNonEnglishNativeLanguage
    }), [
        userInfo,
        setUserInfo,
        updateUserInfo,
        handleLogout,
        isAuthenticated,
        isUserLoaded,
        isNonEnglishNativeLanguage
    ]);
};

export default useUser;
