import { Routes, Route } from 'react-router-dom';
import Practice from '../Practice/Practice';
import Lessons from '../Lessons/Lessons';
import Discover from '../Discover/Discover';
import Learn from '../Learn/Learn';
import Progress from '../Progress/Progress';
const HomePage = () => {
    return (
        <Routes >
            <Route path='/*' element={<Discover />}></Route>
            <Route path='/practice' element={<Practice />}></Route>
            <Route path='/lessons' element={<Lessons />}></Route>
            <Route path='/discover' element={<Discover />}></Route>
            <Route path='/learn' element={<Learn />}></Route>
            <Route path='/progress' element={<Progress />}></Route>
        </Routes >
    );
}

export { HomePage };
