import { useComponentModal } from '../../../../hook/useComponentModal';
import { Word } from '../../../../model/Word';
import styles from './ActionButtons.module.scss';

interface ActionButtonsProps {
    word: Word;
    index: number;
    setIndex: (index: number) => void;
}

const ActionButtons = ({ word, index, setIndex }: ActionButtonsProps) => {
    const { open } = useComponentModal();

    const handleLearn = () => {
        open('FirstWizard', { word });
    };

    const handleSkip = () => {
        setIndex(index + 1);
    };

    return (
        <div className={styles.actionButtons}>
            <button className={styles.learnButton} onClick={handleLearn}>
                Learn
            </button>
            <button className={styles.skipButton} onClick={handleSkip}>
                Skip
            </button>
        </div>
    );
};

export default ActionButtons;