const BaseApiUrl = "https://my.wrdp.app";
const BaseFileLocation = "https://cdn-wordup.com";
const BaseResourceUrl = "https://define.wrdp.app";
const BaseSenseImageUrl = "https://word-images.cdn-wordup.com";
const BaseRazorUrl = "https://my.wrdp.app";
const FriendsBaseUrl = "https://friends.wrdp.app";
const BaseSpeechUrl = "https://get-speech.wrdp.app";
const BaseVoiceUrl = "https://speech.cdn-wordup.com";
const BaseSQS = 'https://sqs.eu-west-1.amazonaws.com/077176806650';

const IsDark = () => false;

export const ImageLocation = (fileName: string) => `${BaseFileLocation}/AppImages/${fileName}`;

export const IconLocation = (fileName: string, forceLight: boolean = false) => `${BaseFileLocation}/AppImages/Icons/${(IsDark() || forceLight ? 'white' : 'black')}/${fileName}.png`;

export const DirectIconLocation = (fileName: string) => `${BaseFileLocation}/AppImages/Icons/${fileName}.png `;

export const ScenariosLocation = (fileName: string) => `${BaseFileLocation}/scenarios/${fileName}.webp `;

export const ResourcesLocation = (fileName: string) => `${BaseResourceUrl}/Resources/${fileName}`;

export const BaseApi = (controller: string, token: string) => `${BaseApiUrl}/${controller}?t=${token}`;

export const SenseImageLocation = (senseId: string) => `${BaseSenseImageUrl}/senses/${senseId}.webp`;

export const SenseVideoLocation = (senseId: string) => `${BaseSenseImageUrl}/video/${senseId}.mp4`;

export const RazorSite = (url: string, token: string | undefined, queryString: string = '') => `${BaseRazorUrl}/${url}?t=${token}${queryString}`;

export const GetWordApiUrl = (wordId: number) => `${BaseResourceUrl}/WordById/${wordId}`;

export const GetFriendsUrl = (userId: string) => `${FriendsBaseUrl}/v2/friends/${userId}`;

export const GetNearbyPeopleUrl = (userId: string, country: string) => `${FriendsBaseUrl}/nearby/${userId}/${country}`;

export const GetWordDataUrl = (wordId: number) => `${BaseResourceUrl}/ById/${wordId}`;

export const GetTipsImageUrl = (example: string) => `${BaseResourceUrl}/TipsImage?example=${example.replaceAll('%', '%25').replaceAll('&', '%26').replaceAll(';', '%3B')}`;

export const GetSpeechUrl = (accent: string = 'gb', text: string) => `${BaseSpeechUrl}/url/q/${accent}/${text}`;

export const GetVoiceUrl = (accent: string = 'gb', sex: string, wordId: string) => `${BaseVoiceUrl}/words/all/${accent}/${sex}/${wordId}`;

export const GetReportContentQueueUrl = () => `${BaseSQS}/bad-video`;

export const GetWellDoneLottieUrl = (fileName: number, token?: string) => `${BaseRazorUrl}/Resource?url=animation/Review/Welldone/${fileName}.json&t=${token}`;