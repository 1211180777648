import { FC } from 'react';
import { Word } from '../../../../../model/Word';
import { WordData } from '../../../../../model/WordData';
import styles from './ButtonSection.module.scss';

interface ButtonSectionProps {
    word: Word;
    wordData: WordData;
}

const ButtonSection: FC<ButtonSectionProps> = ({ word, wordData }) => {

    const handleLearnMore = () => {
        console.log('Learn More');
    };

    const handleNextWord = () => {
        console.log('Next Word');
    };


    return (
        <>
            <button className={styles.learnMoreButton} onClick={() => handleLearnMore()}>Learn More</button>
            <button className={styles.nextWordButton} onClick={() => handleNextWord()}>Next Word</button>
        </>
    );
};

export default ButtonSection;