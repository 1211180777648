import { useState, useCallback } from 'react';
import { HttpMethod, ResponseType } from '../model/enum/enums';

interface FetchResponse<T> {
    response: T | null;
    errorMessage: string | null;
}

interface UseFetchResult<T> {
    send: (url: string, method?: HttpMethod, body?: any, responseType?: ResponseType) => Promise<FetchResponse<T>>;
    sendChain: (requests: Array<{ url: string, method?: HttpMethod, body?: any, responseType?: ResponseType }>) => Promise<FetchResponse<T>[]>;
    isLoading: boolean;
}


function useFetch<T>(): UseFetchResult<T> {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const send = useCallback(async (
        url: string,
        method: HttpMethod = HttpMethod.GET,
        body?: any,
        responseType: ResponseType = ResponseType.Json,
        isChain: boolean = false
    ): Promise<FetchResponse<T>> => {
        setIsLoading(true);

        try {
            const fetchOptions: RequestInit = {
                method
            };

            if (['POST', 'PUT', 'PATCH'].includes(method) && body) {
                fetchOptions.body = JSON.stringify(body);
                fetchOptions.headers = {
                    'Content-Type': 'application/json',
                };
            }

            const fetchResponse = await fetch(url, fetchOptions);
            if (!fetchResponse.ok) {
                throw new Error(`HTTP error! status: ${fetchResponse.status}`);
            }

            let response;
            if (responseType === ResponseType.Json) {
                response = await fetchResponse.json();
            } else {
                response = await fetchResponse.text();
            }

            return { response: response as T, errorMessage: null };
        } catch (e) {
            const errorMessage = e instanceof Error ? e.message : 'An unknown error occurred';
            return { response: null, errorMessage };
        } finally {
            if (!isChain) {
                setIsLoading(false);
            }
        }
    }, []);

    const sendChain = useCallback(async (
        requests: Array<{
            url: string,
            method?: HttpMethod,
            body?: any,
            responseType?: ResponseType
        }>
    ): Promise<FetchResponse<T>[]> => {
        setIsLoading(true);

        try {
            const promises = requests.map(request =>
                send(
                    request.url,
                    request.method,
                    request.body,
                    request.responseType,
                    true
                )
            );

            return await Promise.all(promises);
        } finally {
            setIsLoading(false);
        }
    }, [send]);

    return { send, sendChain, isLoading };
}
export default useFetch;