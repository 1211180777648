import { FC, useCallback, useEffect, useState } from 'react';
import Slide from "react-slick";
import { useComponentModal } from "../../../../hook/useComponentModal";
import { ComponentModalAnimation } from '../../../../model/enum/enums';
import { Word } from '../../../../model/Word';
import { ProTipData, WordData } from '../../../../model/WordData';
import { DirectIconLocation, GetTipsImageUrl } from "../../../../utils/URLUtils";
import ProTip from './ProTip/ProTip';
import classes from "./ProTips.module.scss";
import { ProTipsSliderSettings } from '../../../../utils/SliderSetting';


interface ProTipsProps {
    word: Word;
    wordData: WordData;
}

const ProTips: FC<ProTipsProps> = ({ word, wordData }) => {
    const [tips, setTips] = useState<ProTipData[]>([]);
    const { open } = useComponentModal();

    const navigate = (route: string) => {
        open(route, { word, wordData }, ComponentModalAnimation.Rotate);
    }

    const fetchData = useCallback(async () => {
        const primarySense = wordData?.Senses?.find(sense => sense.id === word.PrimarySenseId);
        const tp = primarySense?.tp?.split('\n');

        const tipsPromises = tp?.map(async (tip, index) => {
            const tipSections = tip.split('|');
            const title = tipSections[0] ?? '';
            const description = tipSections[1] ?? '';
            const example = tipSections[2] ?? '';
            const imageRes = await fetch(GetTipsImageUrl(example));
            const imageUrl = await imageRes.text();
            return { title, description, example, imageUrl };
        });

        const tips = await Promise.all(tipsPromises ?? []);
        setTips(tips);
    }, [word, wordData]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);


    if (tips?.length === 0) return null;

    return (
        <section className={classes.ProTips}>
            <div className={classes.Title}>
                <img src={DirectIconLocation('bulb')} alt="Pro Tips" />
                <h2>Pro Tips</h2>
            </div>
            <div className="slider-container">
                <Slide {...ProTipsSliderSettings(tips?.length)}>
                    {tips?.map((item, index) => (
                        <ProTip key={index} word={word} tip={item} />
                    ))}
                </Slide>
            </div>
            <div className={classes.buttonContainer}>
                <button className={classes.backButton} onClick={() => navigate('FirstWizard')}>Back</button>
                <button className={classes.continueButton} onClick={() => navigate('ThirdWizard')}>Continue</button>
            </div>
        </section>
    );
}

export default ProTips;