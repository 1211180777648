import { Lesson } from '../../model/Lesson';
import { ResourcesLocation, ScenariosLocation } from '../../utils/URLUtils';
import styles from './Lessons.module.scss';
import { LessonsItem } from './LessonsItem/LessonsItem';
import { useEffect, useState } from 'react';

const Lessons = () => {
    const [categories, setCategories] = useState([]);

    const fetchLessons = async () => {
        try {
            const response = await fetch(ResourcesLocation('Scenarios.json'));
            const data = await response.json();
            const { categories } = data;
            setCategories(categories);
        } catch (error) {
            console.error('Error fetching lessons:', error);
        }
    };
    useEffect(() => {
        fetchLessons();
    }, []);

    return (
        <article className={styles.lessons}>
            {categories.map((category: Lesson) => (
                <div key={category.cat} className={styles.section}>
                    <h1 className={styles.title}>{category.cat}</h1>
                    {category.ctx.map((ctx, ctxIndex) => (
                        <LessonsItem key={ctxIndex} ctx={ctx}
                            imageSrc={ScenariosLocation(`${category.ref}${ctx.ref}`)} />
                    ))}
                </div>
            ))}
        </article>
    );
}

export default Lessons;