import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { socialStorage } from "../../utils/StorageUtils";
import { Social } from "../../model/User";

const socialSlice = createSlice({
    name: "social",
    initialState: {
        socialInfo: null as Social | null,
    },
    reducers: {
        getSocial: (state) => {
            state.socialInfo = socialStorage.get();
        },
        setSocial: (state, action: PayloadAction<Social | null>) => {
            socialStorage.set(action.payload);
            state.socialInfo = action.payload;
        },
        updateSocial: (state, action: PayloadAction<Partial<Social>>) => {
            state.socialInfo = socialStorage.update(action.payload);
        },
        logout: (state) => {
            socialStorage.set(null);
            state.socialInfo = null;
        }
    },
});

export const socialActions = { ...socialSlice.actions };
export default socialSlice.reducer;