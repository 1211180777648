import Lottie from 'lottie-react';
import { FC } from 'react';
import { Word } from '../../../../model/Word';
import { WordData } from '../../../../model/WordData';
import { CapitalizeFirstLetter } from '../../../../utils/TextUtils';
import ButtonSection from './ButtonSection/ButtonSection';
import styles from './MainSection.module.scss';

interface MainSectionProps {
    word: Word;
    wordData: WordData;
    translateWord: string;
    animationData: any;
}

const MainSection: FC<MainSectionProps> = ({ word, wordData, translateWord, animationData }) => {

    return (
        <div className={styles.mainSection}>
            {animationData && (
                <div className={styles.lottieContainer}>
                    <Lottie
                        animationData={animationData}
                        loop={true}
                        autoplay={true}
                        className={styles.lottie}
                    />
                </div>
            )}
            {translateWord && <h1 className={styles.translateWord}>{translateWord}</h1>}
            <h2 className={styles.otherMeanings}>
                {`'${CapitalizeFirstLetter(word.Text)}' has ${wordData?.Senses?.length - 1} other meanings`}</h2>
            <ButtonSection word={word} wordData={wordData} />
        </div>
    );
};

export default MainSection;