
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IFrameState {
  isOpen: boolean;
  url: string;
  title?: string;
}

const initialState: IFrameState = {
  isOpen: false,
  url: '',
  title: '',
};

const iframeSlice = createSlice({
  name: 'iframe',
  initialState,
  reducers: {
    openIFrame: (state, action: PayloadAction<{ url: string; title?: string }>) => {
      state.isOpen = true;
      state.url = action.payload.url;
      state.title = action.payload.title;
    },
    closeIFrame: (state) => {
      state.isOpen = false;
      state.url = '';
      state.title = '';
    },
  },
});

export const { openIFrame, closeIFrame } = iframeSlice.actions;
export default iframeSlice.reducer;