import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { userStorage } from "../../utils/StorageUtils";
import { User } from "../../model/User";

interface UserState {
    userInfo: User | null;
    isUserLoaded: boolean;
}

const initialState: UserState = {
    userInfo: null,
    isUserLoaded: false
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        getUser: (state) => {
            state.userInfo = userStorage.get();
        },
        setUser: (state, action: PayloadAction<User | null>) => {
            userStorage.set(action.payload);
            state.userInfo = action.payload;
        },
        updateUser: (state, action: PayloadAction<Partial<User>>) => {
            state.userInfo = userStorage.update(action.payload);
        },
        logout: (state) => {
            Object.assign(state, initialState);
            userStorage.set(null);
        },

    },
});

export const userActions = { ...userSlice.actions };
export default userSlice.reducer;