import React from 'react';
import styles from './ProgressBar.module.scss';

interface WordCountHRProps {
    knownCount?: number;
    learningCount?: number;
    unknownCount?: number;
    totalCount: number;
    height?: number;
}

const ProgressBar: React.FC<WordCountHRProps> = ({ knownCount = 0, learningCount = 0, unknownCount = 0, totalCount, height = 4 }) => {
    const remain = totalCount - (knownCount + learningCount + unknownCount);

    const getPercentage = (count: number) => (count / totalCount) * 100;

    return (
        <div className={styles.progressBar} style={{ height: `${height}px` }}>
            <div
                className={`${styles.hrSegment} ${styles.known}`}
                style={{ width: `${getPercentage(knownCount)}%` }}
            />
            <div
                className={`${styles.hrSegment} ${styles.learning}`}
                style={{ width: `${getPercentage(learningCount)}%` }}
            />
            <div
                className={`${styles.hrSegment} ${styles.unknown}`}
                style={{ width: `${getPercentage(unknownCount)}%` }}
            />
            <div
                className={`${styles.hrSegment} ${styles.total}`}
                style={{ width: `${getPercentage(remain)}%` }}
            />
        </div>
    );
};

export default ProgressBar;