import { FC, ReactNode, useState, useEffect } from 'react';
import styles from './Modal.module.scss';

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    children: ReactNode;
}

const Modal: FC<ModalProps> = ({ isOpen, onClose, children }) => {
    const [shouldRender, setShouldRender] = useState(false);

    useEffect(() => {
        if (isOpen) {
            setShouldRender(true);
        } else {
            const timer = setTimeout(() => {
                setShouldRender(false);
            }, 200); // Match this with your animation duration
            return () => clearTimeout(timer);
        }
    }, [isOpen]);

    if (!shouldRender) return null;

    return (
        <div className={`${styles.modalWrapper} ${isOpen ? styles.open : ''}`}>
            <div
                className={styles.overlay}
                onClick={onClose}
            />
            <div className={styles.modal}>
                {children}
            </div>
        </div>
    );
};

export default Modal;