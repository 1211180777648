import { FC, useState } from 'react';
import { useToast } from '../../../../hook/useToasts';
import { ContentType, MenuPosition } from '../../../../model/enum/enums';
import { Word } from '../../../../model/Word';
import { GetReportContentQueueUrl, IconLocation } from '../../../../utils/URLUtils';
import DropdownMenu from '../../../ui/DropDownMenu/DropdownMenu';
import Spinner from '../../../ui/Spinner/Spinner';
import styles from './MoreOptionsMenu.module.scss';

interface MoreOptionsMenuProps {
    isOpen: boolean;
    onClose: () => void;
    trigger: HTMLSpanElement | null;
    word: Word;
}

const MoreOptionsMenu: FC<MoreOptionsMenuProps> = ({ isOpen, onClose, trigger, word }) => {
    const [loading, setLoading] = useState(false);
    const toast = useToast();

    const handleShare = async () => {
        try {
            if (navigator.share) {
                await navigator.share({
                    title: 'Share Word',
                    text: word.Text
                });
            } else {
                await navigator.clipboard.writeText(word.Text);
                alert('Word copied to clipboard!');
            }
        } catch (error) {
            console.error('Error sharing:', error);
        }
        onClose();
    };


    const handleReport = async () => {

        let jsonData = JSON.stringify({
            WordId: word.ID,
            Word: word.Text,
            Type: ContentType.Image,
            ContentId: word.PrimarySenseId,
            Reason: ""
        });

        setLoading(true)
        let formBody = 'Action=SendMessage&MessageBody=' + encodeURIComponent(jsonData);
        fetch(GetReportContentQueueUrl(), {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: formBody
        }).then(response => {
            if (!response.ok) {
                throw new Error('Failed to submit report');
            }
            toast.showSuccess('Thank you for caring.');
        })
            .catch(error => console.log(error))
            .finally(() => setLoading(false))
        onClose();
    };


    if (loading) return <Spinner />

    return (
        <DropdownMenu
            isOpen={isOpen}
            onClose={onClose}
            trigger={trigger}
            position={MenuPosition.BottomLeft}
        >
            <div className={styles.menuItems}>
                <div className={styles.menuItem} onClick={handleShare}>
                    <img src={IconLocation('share2')} alt="Share" />
                    <span>Share</span>
                </div>
                <div className={styles.menuItem} onClick={handleReport}>
                    <img src={IconLocation('flag_contextmenu')} alt="Report" />
                    <span>Report Image</span>
                </div>
            </div>
        </DropdownMenu>
    );
};

export default MoreOptionsMenu;