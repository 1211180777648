import { FC, useCallback, useEffect, useState } from 'react';
import useFetch from '../../../hook/useFetch';
import useUser from '../../../hook/useUser';
import { ResponseType } from '../../../model/enum/enums';
import { Word } from '../../../model/Word';
import { WordData } from '../../../model/WordData';
import { GetWordDataUrl, RazorSite } from '../../../utils/URLUtils';
import Spinner from '../../ui/Spinner/Spinner';
import HeaderSection from '../HeaderSection/HeaderSection';
import ImageSection from '../ImageSection/ImageSection';
import styles from './FirstWizard.module.scss';
import TextContent from './TextContent/TextContent';

interface FirstWizardProps {
    word: Word;
}

const FirstWizard: FC<FirstWizardProps> = ({ word }) => {
    const { isLoading, sendChain } = useFetch<any>();
    const [wordData, setWordData] = useState<WordData>({} as WordData);
    const { userInfo } = useUser();
    const [finishText, setFinishText] = useState('');

    const fetchData = useCallback(async () => {
        const results = await sendChain([
            { url: GetWordDataUrl(word.ID), responseType: ResponseType.Json },
            { url: RazorSite(`translation/sense/${word.PrimarySenseId}`, userInfo?.Token), responseType: ResponseType.Text }
        ]);

        setWordData(results[0].response);
        setFinishText(results[1].response);

    }, [sendChain, word, userInfo?.Token]);

    useEffect(() => {
        fetchData();
    }, [word, fetchData]);


    if (isLoading) return <Spinner />

    return (
        <>
            <HeaderSection word={word} fillCount={25} />
            <div className={styles.mainContent}>
                <ImageSection word={word} hasRank={false} />
                <TextContent
                    word={word}
                    wordData={wordData}
                    finishText={finishText}
                />
            </div>
        </>
    );
};

export default FirstWizard;