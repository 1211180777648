import { useDispatch, useSelector } from 'react-redux';
import { openComponentModal, closeComponentModal } from '../store/features/componentModalSlice';
import type { RootState } from '../store/store';

export const useComponentModal = () => {
    const dispatch = useDispatch();
    const { isOpen, componentKey, componentProps, animation } = useSelector(
        (state: RootState) => state.componentModal
    );

    const open = (componentKey: string, props?: Record<string, any>, animation?: string) => {
        dispatch(openComponentModal({ componentKey, props, animation }));
    };
    const close = () => {
        dispatch(closeComponentModal());
    };

    return {
        isOpen,
        componentKey,
        componentProps,
        animation,
        open,
        close,
    };
};