import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { learningStorage } from "../../utils/StorageUtils";
import { Learning } from "../../model/User";

const learningSlice = createSlice({
    name: "learning",
    initialState: {
        learningInfo: null as Learning | null,
    },
    reducers: {
        getLearning: (state) => {
            state.learningInfo = learningStorage.get();
        },
        setLearning: (state, action: PayloadAction<Learning | null>) => {
            learningStorage.set(action.payload);
            state.learningInfo = action.payload;
        },
        updateLearning: (state, action: PayloadAction<Partial<Learning>>) => {
            state.learningInfo = learningStorage.update(action.payload);
        },
        logout: (state) => {
            learningStorage.set(null);
            state.learningInfo = null;
        }
    },
});

export const learningActions = { ...learningSlice.actions };
export default learningSlice.reducer;