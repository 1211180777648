import { FC, useCallback, useState } from 'react';
import { useComponentModal } from '../../../hook/useComponentModal';
import useUser from '../../../hook/useUser';
import { Voice } from '../../../model/enum/enums';
import { Word } from '../../../model/Word';
import { GetVoiceUrl, IconLocation } from '../../../utils/URLUtils';
import ProgressBar from '../../ui/ProgressBar/ProgressBar';
import styles from './HeaderSection.module.scss';

interface HeaderSectionProps {
    word: Word;
    fillCount?: number;
    showProgress?: boolean;
}

const HeaderSection: FC<HeaderSectionProps> = ({ word, fillCount, showProgress = true }) => {
    const { userInfo } = useUser();
    const { close } = useComponentModal();
    const [male, setMale] = useState(true);

    const handleVolume = useCallback(() => {
        const accent = userInfo?.Voice;
        const link = GetVoiceUrl(accent, male ? 'm' : 'f', word.ID + '.mp3');
        const audio = new Audio(link);
        audio.play();
        setMale((prevState) => !prevState);
    }, [word, male, setMale, userInfo]);

    return (
        <section className={styles.headerSection}>
            <div className={styles.titleSection}>
                <img src={IconLocation('x')} alt='close' onClick={close} />
                <h1>{word.Text}</h1>
                <img src={IconLocation('fullscreen')} alt='FullScreen' />
            </div>
            <div className={styles.volumeSection}>
                <img src={IconLocation('volume-1')} alt='volume' onClick={handleVolume} />
                <span>/{userInfo?.Voice === Voice.gb ? word.BritishPhonetic : word.AmericanPhonetic}/</span>
                <span>#{word.Rank}</span>
            </div>
            {showProgress && <div className={styles.progressSection}>
                <ProgressBar knownCount={fillCount} totalCount={100} height={10} />
            </div>}
        </section>
    );
};

export default HeaderSection;