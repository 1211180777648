import { useSelector } from 'react-redux';
import { appDispatch, RootState } from '../store/store';
import { socialActions } from '../store/features/socialSlice';
import { Social } from '../model/User';
import { useCallback, useEffect, useMemo } from "react";
import { bindActionCreators } from "redux";

export const useSocial = () => {
    // Memoize bound actions
    const ac = useMemo(
        () => bindActionCreators(socialActions, appDispatch),
        []
    );

    const { socialInfo } = useSelector((state: RootState) => state.social);

    // Load social data on mount if not already loaded
    useEffect(() => {
        if (!socialInfo) {
            ac.getSocial();
        }
    }, [ac, socialInfo]);

    const setSocialInfo = useCallback((info: Social | null) => {
        ac.setSocial(info);
    }, [ac]);

    const updateSocialInfo = useCallback((info: Partial<Social>) => {
        ac.updateSocial(info);
    }, [ac]);

    const hasFriends = useCallback(() => {
        return !!socialInfo?.Friends?.length;
    }, [socialInfo?.Friends]);

    const hasNearbyPeople = useCallback(() => {
        return !!socialInfo?.PeopleNearBy?.length;
    }, [socialInfo?.PeopleNearBy]);

    return useMemo(() => ({
        socialInfo,
        setSocialInfo,
        updateSocialInfo,
        hasFriends,
        hasNearbyPeople
    }), [
        socialInfo,
        setSocialInfo,
        updateSocialInfo,
        hasFriends,
        hasNearbyPeople
    ]);
};