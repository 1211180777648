import { configureStore } from "@reduxjs/toolkit";
import userReducer from './features/userSlice'
import learningReducer from './features/learningSlice'
import socialReducer from './features/socialSlice'
import iframeReducer from './features/iframeSlice'
import componentModalReducer from './features/componentModalSlice'
import toastReducer from './features/toastSlice'
export const store = configureStore({
    reducer: {
        user: userReducer,
        learning: learningReducer,
        social: socialReducer,
        iframe: iframeReducer,
        componentModal: componentModalReducer,
        toast: toastReducer,
    }
})

export type RootState = ReturnType<typeof store.getState>;
export const appDispatch = store.dispatch;