import Footer from './Footer/Footer';
import styles from './Layout.module.scss';
import Header from './Header/Header';
import Main from './Main/Main';
import { FC, memo, PropsWithChildren } from "react";
import useUser from '../../hook/useUser';
import IFrameModal from '../ui/IFrameModal/IFrameModal';
import ComponentModal from '../ui/ComponentModal/ComponentModal';
import ToastContainer from '../ui/Toast/ToastContainer';


const Layout: FC<PropsWithChildren> = ({ children }) => {
    const { isAuthenticated } = useUser();
    return (
        <article className={styles.layout}>
            {
                isAuthenticated() && (
                    <>
                        <Header />
                        <Main>
                            {children}
                        </Main>
                        <Footer />
                        <IFrameModal />
                        <ComponentModal />
                        <ToastContainer />
                    </>
                )}
            {!isAuthenticated() && children}
        </article>
    )
}

export default memo(Layout);