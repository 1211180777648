import { FC } from 'react';
import { Word } from '../../../model/Word';
import { WordData } from '../../../model/WordData';
import HeaderSection from '../HeaderSection/HeaderSection';
import ProTips from './ProTips/ProTips';
interface SecondWizardProps {
    word: Word;
    wordData: WordData;
}

const SecondWizard: FC<SecondWizardProps> = ({ word, wordData }) => {
    return (
        <>
            <HeaderSection word={word} fillCount={50} />
            <ProTips word={word} wordData={wordData} />
        </>
    );
};

export default SecondWizard;
